import { React, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Adeel from "../Images/adeel.jpg";
import Shaunak from "../Images/shaunuk.jpg";
import Nabeel from "../Images/nabeel.jpg";
import { about } from "../text/text";

const styles = (theme) => ({
  ...theme.spreadThis,
  root: {
    flexGrow: 1,
    maxWidth: "1200px",
    margin: "60px auto",
    padding: "12px",
  },
  card: {
    maxWidth: 400,
    margin: "0px auto",
  },
  media: {
    height: 400,
  },
  content: {
    height: 220,
  },
  title: {
    fontWeight: "bold",
  },
});

export class About extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" className={classes.title}>
              Why are we doing this?
            </Typography>
            <Typography gutterBottom variant="h6">
              {about.mission}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={Nabeel}
                title="Nabeel Asghar"
              />
              <CardContent className={classes.content}>
                <Typography gutterBottom variant="h5">
                  Nabeel Asghar
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.title}
                >
                  Full Stack Software Engineer
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {about.nabeel}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={Adeel}
                title="Adeel Asghar"
              />
              <CardContent className={classes.content}>
                <Typography gutterBottom variant="h5" component="h2">
                  Adeel Asghar
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.title}
                >
                  Backend Software Engineer
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {about.adeel}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={Shaunak}
                title="Shaunak Vaghasia"
              />
              <CardContent className={classes.content}>
                <Typography gutterBottom variant="h5">
                  Shaunak Vaghasia
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.title}
                >
                  Application Developer
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {about.shaunak}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(About);
