// Material UI
import {
  Button,
  ButtonBase,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionIcon from "@material-ui/icons/Description";
import React, { Component } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaFileUpload } from "react-icons/fa";
import { VscChecklist } from "react-icons/vsc";
import sideBackground from "../Images/sideBackground.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FullPage, Slide } from "react-full-page";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// API
import axios from "axios";
import { api } from "../util/api";
import Review from "../Components/review";

const styles = (theme) => ({
  ...theme.spreadThis,
  uploadButton: {
    width: "300px",
    height: "75px",
    margin: "20px auto",
    borderRadius: "20px",
  },
  infoTextContainer: {
    maxWidth: 600,
    padding: 10,
    position: "relative",
    top: "50%",
    transform: " translateY(-50%)",
    margin: "0 auto",
  },
  leftGrid: {
    backgroundImage: `url(${sideBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  rightGrid: {
    position: "relative",
  },

  root: {
    margin: "0 auto",
    maxWidth: 345,
    height: 190,
    padding: "10px 5px",
  },

  media: {
    height: 140,
  },
  spacedOut: {
    paddingTop: "30px",
  },
  listText: {
    fontWeight: "bold",
  },
  centered: {
    margin: "0 auto",
    color: theme.palette.secondary.dark,
  },
  uploadContainer: {
    backgroundColor: "lightGray",
    width: "100%",
    height: "100px",
    margin: "15px auto 5px auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  progress: {
    position: "absolute",
  },
  halfGrids: {
    minHeight: "100vh",
  },
  serume: {
    fontWeight: "bold",
  },
  iconGrid: {
    maxHeight: 140,
  },
  icons: {
    fontSize: "calc(35px + 3vw)",
    color: theme.palette.secondary.main,
  },
  bottomGrids: {
    height: "90vh",
    margin: "5vh 0",
  },
  reviewGrid: {
    maxWidth: 1000,
    margin: "0 auto",
    padding: "2vw 0",
  },
  reviewContainer: {
    margin: 0,
    position: "relative",
    top: " 50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cardContainer: {
    padding: "15px",
  },
  reviewText: {
    paddingBottom: "50px",
  },
  viaButton: {
    marginTop: 15,
    width: "100%",
  },
});

export class home extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      email: "",
      name: "",
      resume: null,
      uploadResponse: "",
      questionBody: "",
      errors: null,
      loading: false,
      openSuccess: false,
      openFail: false,
      openContact: false,
      openSuccessContact: false,
      openFailContact: false,
      openViaDialog: false,
      viaEmail: true,
    };
  }

  handleSubmit = () => {
    this.setState({
      errors: null,
      loading: true,
    });
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("resume", this.state.resume, this.state.resume.name);

    axios
      .post(`${api}/upload`, formData)
      .then((res) => {
        if (this.state.viaEmail) {
          this.setState({
            openSuccess: true,
          });
          this.resetState();
        } else {
          this.props.history.push("/appointment");
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openFail: true,
          errors: err.response.data,
        });
      });
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.resetState();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleUpload = () => {
    const fileInput = document.getElementById("uploadResume");
    fileInput.click();
  };

  handleResumeAdd = (event) => {
    const resume = event.target.files[0];
    if (resume) {
      this.setState({
        resume: resume,
      });
    }
  };

  handleCloseAlert = (event, reason) => {
    this.setState({
      openFail: false,
      openSuccess: false,
    });
  };

  resetState = () => {
    this.setState({
      open: false,
      name: "",
      email: "",
      resume: null,
      errors: null,
      loading: false,
      openViaDialog: false,
      openUpload: false,
    });
  };

  toggleContact = () => {
    this.setState({ openContact: !this.state.openContact });
  };

  disableSubmit = () => {
    if (
      !this.state.name ||
      !this.state.email ||
      !this.state.questionBody ||
      this.state.loading
    ) {
      return true;
    }
    return false;
  };

  handleContactSubmit = () => {
    this.setState({
      loading: true,
    });

    const questionObject = {
      name: this.state.name,
      email: this.state.email,
      questionBody: this.state.questionBody,
    };

    axios
      .post(`${api}/contact`, questionObject)
      .then((res) => {
        this.setState({
          openContact: false,
          openSuccessContact: true,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openFailContact: true,
        });
      });
  };

  handleViaChangeEmail = () => {
    this.setState({
      viaEmail: true,
    });
  };

  handleViaChangeConference = () => {
    this.setState({
      viaEmail: false,
    });
  };

  handleViaSubmit = () => {
    this.setState({
      openUpload: true,
    });
  };

  render() {
    const { classes, fullScreen } = this.props;
    return (
      <div className="App">
        <FullPage>
          <Slide>
            <Grid container className={classes.halfGrids}>
              <Grid item xs={0} sm={6} className={classes.leftGrid} />
              <Grid item xs={12} sm={6} className={classes.rightGrid}>
                <div className={classes.infoTextContainer}>
                  <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    className={classes.serume}
                  >
                    serume
                  </Typography>
                  <Typography style={{ fontSize: "20px" }}>
                    We're here to help the students and employees of tomorrow
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CloudUploadIcon style={{ fontSize: 40 }} />}
                    className={classes.uploadButton}
                    onClick={this.handleClickOpen}
                  >
                    <Typography variant="h4">UPLOAD</Typography>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Slide>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={this.state.openUpload ? true : false}
            maxWidth="xs"
            fullScreen={fullScreen}
            style={{ minWidth: 440 }}
          >
            <DialogTitle style={{ color: "#000" }}>Resume Upload</DialogTitle>
            {this.state.openUpload ? (
              <>
                <DialogContent>
                  <DialogContentText>
                    To have your resume reviewed, upload it along with a name
                    and email. You may change any personal details as you see
                    fit. We will not share any of your details.{" "}
                    <b>Please upload Word files, this helps with editing.</b>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    value={this.state.name}
                    color="secondary"
                    onChange={this.handleChange}
                    fullWidth
                    error={this.state.errors?.name ? true : false}
                    helperText={this.state.errors?.name}
                    required
                  />
                  <TextField
                    margin="dense"
                    name="email"
                    label="Email Address"
                    type="email"
                    value={this.state.email}
                    color="secondary"
                    onChange={this.handleChange}
                    fullWidth
                    error={this.state.errors?.email ? true : false}
                    helperText={this.state.errors?.email}
                    required
                  />
                  <ButtonBase
                    className={classes.uploadContainer}
                    onClick={this.handleUpload}
                  >
                    <input
                      type="file"
                      id="uploadResume"
                      hidden="hidden"
                      onChange={this.handleResumeAdd}
                      accept="application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                     application/pdf"
                    />
                    {this.state.resume ? (
                      <>
                        <DescriptionIcon
                          color="secondary"
                          className={classes.centered}
                        />
                        <Typography varint="h6" className={classes.centered}>
                          {this.state.resume.name}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CloudUploadIcon
                          color="secondary"
                          className={classes.centered}
                        />
                        <Typography varint="h6" className={classes.centered}>
                          UPLOAD
                        </Typography>
                      </>
                    )}
                  </ButtonBase>
                  {this.state.errors?.file && (
                    <Typography color="error" className={classes.centered}>
                      {this.state.errors.file}
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions style={{ padding: "15px 24px" }}>
                  <Button
                    variant="outlined"
                    onClick={this.handleClose}
                    color="secondary"
                    disabled={this.state.loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={this.handleSubmit}
                    color="secondary"
                    disabled={!this.state.resume || this.state.loading}
                  >
                    Submit
                    {this.state.loading && (
                      <CircularProgress
                        color="secondary"
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <DialogContentText>
                    What you will get from us:
                    {this.state.viaEmail ? (
                      <>
                        <ul>
                          <li>Your resume will be reviewed</li>
                          <li>Serume will edit and add notes to the resume</li>
                          <li>Recieved tailored resume within a week</li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <ul>
                          <li>Your resume will be reviewed</li>
                          <li>Serume will edit and add notes to the resume</li>
                          <li>
                            Recieve valueable career and resume advice via
                            conference call
                          </li>
                          <li>Recieved tailored resume after call</li>
                        </ul>
                      </>
                    )}
                  </DialogContentText>

                  <ButtonGroup className={classes.viaButton} fullWidth>
                    <Button
                      variant={this.state.viaEmail ? "contained" : "outlined"}
                      color="secondary"
                      onClick={this.handleViaChangeEmail}
                    >
                      Via Email
                    </Button>
                    <Button
                      variant={!this.state.viaEmail ? "contained" : "outlined"}
                      color="secondary"
                      onClick={this.handleViaChangeConference}
                    >
                      Via Conference
                    </Button>
                  </ButtonGroup>
                </DialogContent>
                <DialogActions style={{ padding: "15px 24px" }}>
                  <Button
                    variant="contained"
                    onClick={this.handleViaSubmit}
                    color="secondary"
                    fullWidth
                  >
                    Continue
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>

          <Snackbar
            onClose
            open={this.state.openSuccess}
            autoHideDuration={4000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              Success! We will contact you via email.
            </MuiAlert>
          </Snackbar>

          <Snackbar
            onClose
            open={this.state.openFail}
            autoHideDuration={4000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error">
              Something went wrong...
            </MuiAlert>
          </Snackbar>

          <Slide>
            <Grid
              container
              direction="column"
              justify="space-evenly"
              alignItems="center"
              className={classes.bottomGrids}
              spacing={24}
            >
              <Grid item xs={12} className={classes.iconGrid}>
                <FaFileUpload className={classes.icons} />
                <Typography variant="h6" className={classes.listText}>
                  Upload your resume{" "}
                </Typography>
                <Typography variant="h6">and we'll take a look</Typography>
              </Grid>

              <Grid item xs={12} className={classes.iconGrid}>
                <VscChecklist className={classes.icons} />
                <Typography variant="h6" className={classes.listText}>
                  We'll review it via email
                </Typography>
                <Typography variant="h6">or conference call</Typography>
              </Grid>

              <Grid item xs={12} className={classes.iconGrid}>
                <BsPencilSquare className={classes.icons} />
                <Typography variant="h6" className={classes.listText}>
                  Receive a resume tailored
                </Typography>
                <Typography variant="h6">all for free</Typography>
              </Grid>
            </Grid>
          </Slide>

          <Slide>
            <div className={classes.reviewContainer}>
              <Typography variant="h6" className={classes.reviewText}>
                <b>Still not convinced?</b> Check out reviews by satisfied
                students below
              </Typography>

              <Review fullScreen={fullScreen} />
            </div>
          </Slide>
        </FullPage>
      </div>
    );
  }
}

export default withStyles(styles)(home);
