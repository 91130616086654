import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import Home from "./Pages/home";
import About from "./Pages/about";
import Navbar from "./Components/Navbar";
import Appointment from "./Pages/appointment";
import Review from "./Pages/review";
import Contact from "./Pages/contact";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { useHistory } from "react-router-dom";

const breakpoints = createBreakpoints({
  xs: 0,
  sm: 730,
  md: 1000,
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000000",
    },
    secondary: {
      light: "#82e9de",
      main: "#4db6ac",
      dark: "#00867d",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 730,
      md: 1000,
    },
  },
  progress: {
    position: "absolute",
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: "1.25rem",
        [breakpoints.down("xs")]: {
          fontSize: "1rem",
        },
      },
    },
  },
});

function App(props) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Router>
          <Navbar />
          <Switch>
            <div id="container">
              <Route
                exact
                path="/"
                render={(props) => <Home {...props} fullScreen={fullScreen} />}
              />
              <Route path="/about">
                <About />
              </Route>
              <Route path="/appointment">
                <Appointment />
              </Route>
              <Route path="/review">
                <Review />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </div>
          </Switch>
        </Router>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
