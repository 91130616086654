import React, { Component } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Hidden,
  Typography,
  Button,
  CardActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { plainReviews } from "../text/text";
import StarRatings from "react-star-ratings";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";

const styles = (theme) => ({
  ...theme.spreadThis,
  oddRoot: {
    padding: "8px",
  },
  evenRoot: {
    padding: "8px",
  },
  cardContent: {
    padding: "0px 16px",
  },
  reviewGrid: {
    maxWidth: 1000,
    margin: "0 auto",
    padding: "2vw 0",
  },
  reviewContainer: {
    height: "100vh",
    padding: "5px",
  },
  cardContainer: {
    padding: "25px 15px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  shortDesc: {
    fontWeight: "bold",
  },
  cardActions: {
    padding: "0 8px",
  },
  card: {
    maxWidth: 480,
    margin: "0 auto",
    padding: "10px 5px",
  },

  reviewName: {
    padding: "10px 0",
  },
});

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1800 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export class review extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      reviews: [],
    };
  }

  componentDidMount() {
    axios
      .get("https://us-central1-resume-d78a8.cloudfunctions.net/api/review")
      .then((res) => {
        this.setState({ reviews: res.data.reviews });
      })
      .catch(() => {
        this.setState({ reviews: plainReviews });
      });
  }

  togglePanel = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.carousel}>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          infinite={true}
          keyBoardControl={true}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          autoPlay={this.props.fullScreen ? true : false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {this.state?.reviews.length > 0 &&
            this.state.reviews.map((review, index) => {
              return (
                <div style={{ margin: 25 }}>
                  <Card
                    className={
                      index % 2 === 0 ? classes.evenRoot : classes.oddRoot
                    }
                    className={classes.card}
                  >
                    <StarRatings
                      rating={5}
                      starDimension="30px"
                      starRatedColor="#4db6ac"
                      numberOfStars={review.rating}
                      name="rating"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        color="textSecondary"
                        className={classes.reviewName}
                      >
                        {review.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.shortDesc}
                      >
                        {review.shortDesc}
                      </Typography>
                    </CardContent>
                    <Hidden xsDown>
                      <CardActions className={classes.cardActions}>
                        <IconButton
                          color="secondary"
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: this.state.open,
                          })}
                          onClick={() => this.togglePanel()}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </CardActions>

                      <Collapse
                        in={this.state.open}
                        timeout="auto"
                        unmountOnExit
                      >
                        <CardContent className={classes.cardContent}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="left"
                          >
                            {review.description}
                          </Typography>
                        </CardContent>
                      </Collapse>
                    </Hidden>
                  </Card>
                </div>
              );
            })}
        </Carousel>
      </div>
    );
  }
}

export default withStyles(styles)(review);
