import { React, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import StarRatings from "react-star-ratings";
import logo from "../Images/logo.png";
import axios from "axios";
import { api } from "../util/api";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
  root: {
    flexGrow: 1,
    maxWidth: 400,
    height: "100vh",
    margin: "0 auto",
    padding: 10,
  },

  rating: {
    margin: "0 auto",
  },
  logo: {
    width: 140,
    marginBottom: 15,
  },
  progress: {
    position: "absolute",
  },
});

export class Review extends Component {
  state = {
    loading: false,
    errors: null,
    openSuccess: false,
    openFail: false,
    rating: 0,
    name: "",
    review: "",
    endReview: false,
  };

  changeRating = (value) => {
    this.setState({
      rating: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  disableButton = () => {
    if (
      !this.state.name ||
      !this.state.review ||
      this.state.rating === 0 ||
      this.state.loading
    ) {
      return true;
    }
    return false;
  };

  handleCloseAlert = () => {
    this.setState({
      openFail: false,
      openSuccess: false,
    });
  };

  handleSubmit = () => {
    this.setState({
      errors: null,
      loading: true,
    });

    let theReview = {};
    theReview.name = this.state.name;
    theReview.review = this.state.review;
    theReview.rating = this.state.rating;

    axios
      .post(`${api}/review`, theReview)
      .then((res) => {
        this.setState({
          openSuccess: true,
          loading: false,
          endReview: true,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openFail: true,
          errors: err.response.data,
        });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12} className={classes.reviewContainer}>
          {this.state.endReview ? (
            <>
              <div style={{ textAlign: "center" }}>
                <a href="/">
                  <img src={logo} className={classes.logo} />
                </a>
                <Typography variant="body1">
                  Thank you for leaving us a review. We greatly appreciate it
                  and wish you good luck in your career!
                </Typography>
              </div>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <img src={logo} className={classes.logo} />
                <StarRatings
                  rating={this.state.rating}
                  starRatedColor="#4db6ac"
                  starHoverColor="#4db6ac"
                  changeRating={this.changeRating}
                  numberOfStars={5}
                  name="rating"
                  starDimension="40px"
                />
              </div>
              <TextField
                margin="normal"
                name="name"
                label="Name"
                type="text"
                value={this.state.name}
                color="secondary"
                variant="outlined"
                placeholder="Enter your first name"
                fullWidth
                required
                onChange={this.handleChange}
                error={this.state.errors?.name ? true : false}
                helperText={this.state.errors?.name}
              />
              <TextField
                margin="normal"
                name="review"
                label="Review"
                type="email"
                value={this.state.review}
                color="secondary"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={5}
                placeholder="Enter a short review."
                onChange={this.handleChange}
                error={this.state.errors?.review ? true : false}
                helperText={this.state.errors?.review}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                disabled={this.disableButton()}
                onClick={this.handleSubmit}
              >
                {this.state.loading && (
                  <CircularProgress
                    color="secondary"
                    className={classes.progress}
                  />
                )}
                Submit
              </Button>
            </>
          )}

          <Snackbar
            onClose
            open={this.state.openSuccess}
            autoHideDuration={4000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              Success! Your review has been added.
            </MuiAlert>
          </Snackbar>

          <Snackbar
            onClose
            open={this.state.openFail}
            autoHideDuration={4000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error">
              Something went wrong...
            </MuiAlert>
          </Snackbar>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Review);
