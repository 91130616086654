export const plainReviews = [
    {
        name: "Sam",
        rating: 5,
        description:
            "I sent Adeel my resume and asked him for feedback. It was through social media and I was surprised at the professionalism shown by him. He tried to edit my resume but the formatting was becoming an issue so he literally just remade a new one for me, it looked very professional and I get compliments on it to this day. Definitely a good medium to go through for your resume needs.",
        shortDesc:
            "...Definitely a good medium to go through for your resume needs.",
    },
    {
        name: "Rahimah",
        rating: 5,
        description:
            "Serume is a great resource, especially for college students with less experience looking to catch the eye of recruiters. Adeel was very helpful in formatting my resume, rephrasing bullet points to sound more professional, and giving interview advice. I love the way my resume looks now! Would definitely recommend this great resource!",
        shortDesc:
            "Serume is a great resource for college students looking to catch the eye of recruiters...",
    },
    {
        name: "Mrinnal",
        rating: 5,
        description:
            "I asked Shaunak and Nabeel for resume help as I was not receiving any call backs. They helped me step by step by first looking at my old resume and noting all the things are I can improve on. We looked at the format and style of the document and then improved on the layout. I now have heard back from numerous companies for interviews with my improved resume. I definitely recommend their services for anyone struggling with job searches.",
        shortDesc:
            "...I definitely recommend their services for anyone struggling with job searches.",
    },

    {
        name: "Jawad",
        rating: 5,
        description:
            "I loved their service. Adeel helped me better tailor my resume to attract more attention from many Fortune 500 companies. He provided me with the one on one time I needed. He went above and beyond by following up about my application journey afterwards many times.",
        shortDesc:
            "...Serume went above and beyond by following up about my job application afterwards.",
    },
    {
        name: "Sean",
        rating: 5,
        description:
            "Serume had great service! Helped me hear back from more employers than before. Took time out of their busy day and pointed flaws in formatting and wording in my resume. Highly recommend them to help you if you aren't recieving callbacks.",
        shortDesc:
            "...Highly recommend them to help you if you aren't recieving callbacks.",
    },
    {
        name: "Showrabh",
        rating: 5,
        description:
            "Thank you so much for the great advice! I definitely feel more confident applying for jobs and have a prepared plan of action. A great experience and possibly the best resume advice I've received. Enjoyed the conversation and great overall advice. Will be recommending Serume to some friends!",
        shortDesc:
            "...I definitely feel more confident applying for jobs and have a prepared plan of action...",
    },
];

export const about = {
    nabeel:
        "Hello there! I have interviewed at dozens of Fortune 500 companies from Amazon to JPMorgan and Google and I know how hard it can be to interview and to get a job offer. Graduating from Wayne State University, you have to work harder than typical. I hope to leverage my experience to help you get your foot in the door. Currently working at a startup in Detroit after leaving Ford.",
    shaunak:
        "Hi! I am currently working as a Software Developer with loads of application development experience. I know how difficult it can be to find a job in the programming world without professional internship experience, but it all starts with a solid resume. Let me help you build that foundation so you can start hearing back as soon as possible. I am currently working at iRobot after leaving BeetSheet.",
    adeel:
        "Hello! I have received offers from companies such as Amazon, Google, and Square. My experiences have helped me build a solid resume and interview strategy that can help you get recognized and stand out amongst other candidates. I am currently working at Square after leaving Ford.",
    mission:
        "We created this website as a free resource to help students to start their professional careers leveraging our experiences.",
};
