import { Button, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React, { Component } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";

const styles = {
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    paddingRight: 0,
  },
  right: {
    textAlign: "right",
    padding: 0,
    margin: 0,
  },
  root: {
    "& > *": {
      margin: 10,
    },
  },
};

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      location: null,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <HideOnScroll {...this.props}>
        <AppBar
          className={classes.appBar}
          style={{
            visibility:
              window.location.pathname === "/appointment" ||
              window.location.pathname === "/review"
                ? "hidden"
                : "visible",
          }}
        >
          <Toolbar style={{ padding: 0 }}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} className={classes.right}>
                <div className={classes.root}>
                  {window.location.pathname !== "/review" &&
                    window.location.pathname !== "/" && (
                      <Button
                        onClick={() =>
                          this.setState({ location: window.location.pathname })
                        }
                        component={Link}
                        to="/"
                        color="secondary"
                        size="medium"
                        variant="outlined"
                      >
                        Home
                      </Button>
                    )}
                  {window.location.pathname !== "/review" &&
                    window.location.pathname !== "/about" &&
                    window.location.pathname !== "/contact" && (
                      <Button
                        onClick={() =>
                          this.setState({ location: window.location.pathname })
                        }
                        component={Link}
                        to="/about"
                        color="secondary"
                        size="medium"
                        variant="outlined"
                      >
                        About Us
                      </Button>
                    )}
                  {window.location.pathname !== "/contact" &&
                    window.location.pathname !== "/contact" && (
                      <Button
                        onClick={() =>
                          this.setState({ location: window.location.pathname })
                        }
                        component={Link}
                        to="/contact"
                        color="secondary"
                        size="medium"
                        variant="outlined"
                      >
                        Contact Us
                      </Button>
                    )}
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    );
  }
}

export default withStyles(styles)(withRouter(Navbar));
