import { React, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, Snackbar, TextField } from "@material-ui/core";
import StarRatings from "react-star-ratings";
import logo from "../Images/logo.png";
import axios from "axios";
import { api } from "../util/api";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
  root: {
    flexGrow: 1,
    maxWidth: 400,
    height: "80vh",
    margin: "10vh auto",
    padding: 10,
  },

  rating: {
    margin: "0 auto",
  },
  logo: {
    width: 140,
    marginBottom: 15,
  },
  progress: {
    position: "absolute",
  },
});

export class Review extends Component {
  state = {
    loading: false,
    errors: null,
    openSuccess: false,
    openFail: false,
    name: "",
    email: "",
    body: "",
    endContact: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  disableButton = () => {
    if (
      !this.state.name ||
      !this.state.email ||
      !this.state.body ||
      this.state.loading
    ) {
      return true;
    }
    return false;
  };

  handleCloseAlert = () => {
    this.setState({
      openFail: false,
      openSuccess: false,
    });
  };

  handleSubmit = () => {
    this.setState({
      errors: null,
      loading: true,
    });

    let contactObject = {};
    contactObject.name = this.state.name;
    contactObject.email = this.state.email;
    contactObject.body = this.state.body;

    axios
      .post(`${api}/contact`, contactObject)
      .then((res) => {
        this.setState({
          openSuccess: true,
          loading: false,
          endContact: true,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openFail: true,
          errors: err.response.data,
        });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12} className={classes.reviewContainer}>
          {this.state.endContact ? (
            <>
              <div style={{ textAlign: "center" }}>
                <a href="/">
                  <img src={logo} className={classes.logo} />
                </a>
                <Typography variant="body1">
                  Thank you for contacting us. We will reach out to you as soon
                  as possible.
                </Typography>
              </div>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <img src={logo} className={classes.logo} />
                <Typography variant="h5">Contact Us</Typography>
              </div>
              <TextField
                margin="normal"
                name="name"
                label="Name"
                type="text"
                value={this.state.name}
                color="secondary"
                variant="outlined"
                placeholder="Enter your first name"
                fullWidth
                required
                onChange={this.handleChange}
                error={this.state.errors?.name ? true : false}
                helperText={this.state.errors?.name}
              />
              <TextField
                margin="normal"
                name="email"
                label="Email"
                type="text"
                value={this.state.email}
                color="secondary"
                variant="outlined"
                fullWidth
                required
                placeholder="Email"
                onChange={this.handleChange}
                error={this.state.errors?.email ? true : false}
                helperText={this.state.errors?.email}
              />

              <TextField
                margin="normal"
                name="body"
                label="Body"
                type="text"
                value={this.state.body}
                color="secondary"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={5}
                placeholder="Body of message"
                onChange={this.handleChange}
                error={this.state.errors?.body ? true : false}
                helperText={this.state.errors?.body}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                disabled={this.disableButton()}
                onClick={this.handleSubmit}
              >
                {this.state.loading && (
                  <CircularProgress
                    color="secondary"
                    className={classes.progress}
                  />
                )}
                Submit
              </Button>
            </>
          )}

          <Snackbar
            onClose
            open={this.state.openSuccess}
            autoHideDuration={4000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              Success! Your response has been noted.
            </MuiAlert>
          </Snackbar>

          <Snackbar
            onClose
            open={this.state.openFail}
            autoHideDuration={4000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error">
              Something went wrong...
            </MuiAlert>
          </Snackbar>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Review);
