import React, { Component } from "react";
import { InlineWidget } from "react-calendly";

class appointment extends Component {
  render() {
    return (
      <div>
        <InlineWidget url="https://calendly.com/serume" />
      </div>
    );
  }
}

export default appointment;
